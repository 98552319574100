
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
import _ from 'lodash'

export default {
  name: 'GroupsDialog',
  components: {
    CustomDialog
  },
  props: {
    value: { type: Boolean, default: false },
    item: { type: Object },
    roles: { type: Array, required: true }
  },

  data: () => ({
    open: false,
    name: '',
    description: '',
    selected_group: null,
    group_items: []
  }),

  computed: {
    are_fields_valid() {
      return !!(this.name && this.selected_group) && this.does_something_changed
    },
    isEditDialog() {
      return this.item ? true : false;
    },
    does_something_changed() {
      if (!this.isEditDialog) return true //if it is not Edit Dialog
      return (
        this.name !== this.item.name ||
        this.description !== this.item.description ||
        this.selected_group !== this.item.selected_group
      )
    },
    title() {
      return this.isEditDialog ? `Update Group : ${this.item.name}` : 'New Group'
    }
  },

  watch: {
    value(new_val) {
      this.open = new_val
    },
    open(new_val) {
      this.$emit('input', new_val)
    },
    item: {
      handler(new_val) {
        new_val && this.update_fields(new_val)
      },
      deep: true,
      immediate: true
    },
    roles: {
      handler(new_val) {
        if (new_val) {
          this.group_items = _.cloneDeep(new_val).filter(i => i.slug !== 'admin')
        }
      },
      deep: true,
      immediate: true
    },
  },

  methods: {
    cancel() {
      this.open = false
    },
    save() {
      if (this.validation_passed()) {
        const fields_to_save = {
          name: this.name,
          description: this.description,
          selected_group: this.selected_group
        }
        this.$emit(this.isEditDialog ? 'update' : 'save', fields_to_save)
      }
    },
    validation_passed() {
      if (this.name && this.selected_group && this.does_something_changed) {
        return true
      }
      this.appSnackbar( 'Fill in required fields!', 'error')
      this.$event.$emit('btnloading_off', false)
      return false
    },
    update_fields(fields) {
      const new_fields = _.cloneDeep(fields)
      this.name = new_fields.name
      this.description = new_fields.description
      this.selected_group = new_fields.id
    },

    clear_and_close() {
      Object.assign(this.$data, this.$options.data.apply(this))
      this.cancel() //close the modal
    }
  }
}
