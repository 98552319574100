<template>
  <v-card
    elevation="3"
    v-bind="$attrs"
    class="member-card rounded-10"
    v-if="theUser"
  >
    <v-card-title>
      <v-spacer />
      <h3 class="fw-700 primary--text">
        {{ theUser.fullname | ucwords }}
      </h3>
      <v-spacer />
      <v-menu bottom left>
        <template v-slot:activator="{ on }">
          <v-icon color="primary" fab class="settings" v-on="on">
            mdi-dots-horizontal
          </v-icon>
        </template>
        <v-list dense class="project-members-section-one">
          <v-list-item
            v-if="can_edit && isEditable"
            @click="$emit('edit', theUser)"
          >
            <v-list-item-subtitle class="fw-900 primary--text">
              <v-icon color="primary" left>mdi-circle-edit-outline</v-icon>
              {{ editLabel }}
            </v-list-item-subtitle>
          </v-list-item>
          <v-list-item
            v-if="can_delete && isDeletable"
            @click="$emit('delete', theUser)"
          >
            <v-list-item-subtitle class="fw-900 primary--text">
              <v-icon color="primary" left>mdi-delete-circle-outline</v-icon>
              {{ deleteLabel }}
            </v-list-item-subtitle>
          </v-list-item>
          <v-list-item v-if="notSelf" @click="chatThisUser">
            <v-list-item-subtitle class="fw-900 primary--text">
              <v-icon color="primary" left>mdi-wechat</v-icon>
              {{ messageLabel }}
            </v-list-item-subtitle>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-title>
    <v-card-text class="card-body">
      <h5 class="text-center mb-3 font-weight-bold primary--text">
        {{ theUser.email }}
      </h5>
      <v-layout class="align-center">
        <v-spacer />
        <v-badge
          bordered
          bottom
          :color="is_online ? 'success' : 'grey'"
          dot
          offset-x="18"
          offset-y="18"
        >
          <v-avatar class="cursor-pointer" size="80" @click="navigateProfile">
            <v-img
              :src="theUser.image_url"
              :alt="theUser.first_name"
              contain
            ></v-img>
          </v-avatar>
        </v-badge>
        <v-spacer />
      </v-layout>
      <div class="text-center mt-3">
        <strong class="primary--text">
          {{ theUser.job_title | ucwords }}
        </strong>
      </div>
      <v-expand-transition v-if="showAffiliation && theUser.is_client">
        <div v-show="show_expand">
          <v-card-text v-if="hasCompanies">
            <div
              class="
                fullwidth
                d-flex
                align-center
                justify-start
                primary--text
                subtitle-2
              "
              v-for="company in theUser.client_companies"
              :key="company.id"
            >
              <v-avatar size="25" class="ma-1">
                <v-img
                  v-if="company.company_logo"
                  contain
                  :src="company.company_logo"
                ></v-img>
                <v-img
                  v-else
                  contain
                  :src="settings.noCompanyLogo"
                  :lazy-src="settings.loader"
                ></v-img>
              </v-avatar>
              {{ company.name | ucwords }}
            </div>
          </v-card-text>
          <v-card-text v-else>
            <div
              class="
                fullwidth
                d-flex
                align-center
                justify-start
                primary--text
                subtitle-2
              "
            >
              <v-avatar size="25" class="ma-1">
                <v-img
                  v-if="theUser.company.company_logo"
                  contain
                  :src="theUser.company.company_logo"
                ></v-img>
                <v-img
                  v-else
                  contain
                  :lazy-src="settings.loader"
                  :src="settings.noCompanyLogo"
                ></v-img>
              </v-avatar>
              {{ theUser.company.name | ucwords }}
            </div>
          </v-card-text>
        </div>
      </v-expand-transition>
    </v-card-text>
    <v-divider />
    <v-card-actions class="card-footer">
      <v-row dense align="center" justify="center">
        <v-col class="text-center primary--text" v-if="showTaskCount">
          <h2 class="font-weight-bold">{{ theUser.tasks_count || 0 }}</h2>
          <h3 class="caption font-weight-bold">Tasks</h3>
        </v-col>
        <v-divider vertical v-if="showProjectCount && showTaskCount" />
        <v-col class="text-center primary--text" v-if="showProjectCount">
          <h2 class="font-weight-bold">{{ theUser.projects_count || 0 }}</h2>
          <h3 class="caption font-weight-bold">Projects</h3>
        </v-col>
        <v-divider vertical v-if="showAffiliation && theUser.is_client" />
        <v-col
          v-if="showAffiliation && theUser.is_client"
          class="text-center primary--text cursor-pointer"
          @click="show_expand = !show_expand"
        >
          <v-badge
            offset-x="-20"
            right
            bottom
            offset-y="35"
            bordered
            color="primary"
            :icon="show_expand ? 'mdi-chevron-up' : 'mdi-chevron-down'"
            overlap
          >
            <div>
              <h2 class="font-weight-bold">
                {{ hasCompanies ? theUser.client_companies.length : 0 }}
              </h2>
              <h3 class="caption font-weight-bold">Affiliation</h3>
            </div>
          </v-badge>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import _ from 'lodash'
export default {
  inheritAttrs: false,
  name: 'UserCard',
  props: {
    item: { type: Object, required: true },
    canBeDeleted: { type: Boolean, default: false },
    showTaskCount: { type: Boolean, default: true },
    showProjectCount: { type: Boolean, default: true },
    showAffiliation: { type: Boolean, default: false },
    deleteLabel: { type: String, default: 'Delete' },
    editLabel: { type: String, default: 'Edit' },
    messageLabel: { type: String, default: 'Send Message' },
    isEditable: { type: Boolean, default: true },
    isDeletable: { type: Boolean, default: true }
  },
  watch: {
    item: {
      handler: function (val) {
        this.theUser = _.cloneDeep(val)
      },
      deep: true,
      immediate: true
    }
  },
  data: () => ({
    show_expand: false,
    theUser: null
  }),
  computed: {
    onlineUsers() {
      return this.$store.getters['onlineUsers/all_users']
    },
    permissions() {
      return this.$_permissions.get('hq_members')
    },
    loggedUser() {
      return this.$store.getters.user
    },
    notSelf() {
      return this.theUser && this.theUser.id !== this.loggedUser.id
    },
    can_delete() {
      if (this.loggedUser.is_admin) {
        return true
      }
      return this.permissions && this.permissions.delete
    },
    can_edit() {
      if (this.loggedUser.is_admin) {
        return true
      }
      if (this.loggedUser.id === this.theUser.id) {
        return true
      }
      return this.permissions && this.permissions.update
    },
    can_view() {
      if (this.loggedUser.is_admin) {
        return true
      }
      return this.permissions && this.permissions.view
    },
    is_online() {
      let index = this.onlineUsers.findIndex((i) => i.id === this.theUser.id)
      return !!~index
    },
    hasCompanies() {
      return this.theUser &&
        this.theUser.hasOwnProperty('client_companies') &&
        typeof this.theUser.client_companies !== 'undefined' &&
        this.theUser.client_companies.length > 0
        ? true
        : false
    }
  },
  methods: {
    chatThisUser() {
      this.$router.push({
        name: 'chat',
        params: { conversation_id: null },
        query: { target: this.theUser.id }
      })
    },
    navigateProfile() {
      this.$router.push({
        name: 'profile',
        params: { username: this.theUser.username }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.member-card {
  .card-body {
    .v-badge--dot .v-badge__badge {
      border-radius: 6.5px !important;
      height: 15px !important;
      min-width: 0;
      padding: 0;
      width: 15px !important;
    }
  }
}
</style>
