import { render, staticRenderFns } from "./TeamsDialog.vue?vue&type=template&id=0ec508aa&"
import script from "./TeamsDialog.js?vue&type=script&lang=js&"
export * from "./TeamsDialog.js?vue&type=script&lang=js&"
import style0 from "./TeamsDialog.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports