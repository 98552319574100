import request from "@/services/axios_instance";
import _ from 'lodash'
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
import CustomPhoneInput from "@/common/CustomPhoneInput";

export default {
    name: 'TeamsDialog',
    props: {
        value: { type: Boolean, default: false },
        hasRoleChange: { type: Boolean, default: true },
        item: Object,
        roles: Array
    },
    components: {
        CustomDialog,
        CustomPhoneInput
    },
    computed: {
        isEditDialog() {
            return this.item ? true : false;
        },
        disabled_btn() {
            return !this.valid
        },
        title() {
            return this.isEditDialog ? 'Update User' : 'Add New User'
        },
        payload() {
            let fields = {
                first_name: this.mapping.first_name,
                last_name: this.mapping.last_name,
                email: this.mapping.email,
                rate: this.mapping.rate,
                address: this.mapping.address,
                telephone: this.mapping.contact_number,
                role_id: this.mapping.role_id,
                job_title: this.mapping.job_title
            }
            if (this.isEditDialog) {
                fields.id = this.item.id
            }
            fields.admin_set_password = false
            if (this.show_create_password) {
                fields.admin_set_password = true
                fields.password = this.mapping.password
                fields.password_confirmation = this.mapping.repeat_password
            }
            return fields
        }, 
    },
    data: () => ({ 
        valid: false,
        btnloading: false,
        open: false,
        telephone: null,
        defaultCountryCode: null,
        group_items: [],
        show_password: false,
        show_repeat_password: false,
        loading: false,
        telephone_is_valid: false,
        show_create_password: false,
        show_create_password_label: [
            { text: 'Let user set their password', value: false },
            { text: 'Manually set user password', value: true }
        ],
        avatar: null,
        mapping: {
            first_name: null,
            last_name: null,
            role_id: null,
            job_title: null,
            email: null,
            rate: null,
            address: null,
            password: null,
            repeat_password: null,
            contact_number: {
                input: null,
                number: null,
                valid: false,
                dialCode: null,
                countryCode: null
            },
        },
        defaultTelephone: {
            input: null,
            number: null,
            valid: false,
            dialCode: null,
            countryCode: null
        }
    }),
    watch: {
        value(new_val) {
            this.open = new_val
            if (new_val) {
                this.resetValidation()
                this.btnloading = false;
                if (!this.isEditDialog) this.reset();
            }
        },
        open(new_val) {
            this.$emit('input', new_val)
        },
        item: {
            handler(new_val) {
                this.mapItem(new_val)
            },
            immediate: true,
            deep: true
        },
        show_create_password(new_val) {
            if (!this.show_create_password) {
                this.mapping.password = this.mapping.repeat_password = ''
            }
        }
    },
    methods: {
        cancel() {
            this.open = false
        },
        save() {
            if (this.valid) {
                this.btnloading = true
                this.$emit(this.isEditDialog ? 'update' : 'save', this.payload)
            }
        },
        mapItem(val) {
            if (!val) {
                this.reset()
                return;
            }
            let item = _.cloneDeep(val)
            let croles = Object.keys(item.user_roles);
            this.mapping = {
                first_name: item.first_name,
                last_name: item.last_name,
                role_id: croles.length ? croles[0] : null, //todo
                job_title: item.job_title,
                email: item.email,
                rate: item.rate,
                address: item.location,
                password: null,
                repeat_password: null,
                contact_number: _.cloneDeep(this.defaultTelephone)
            }
            if (item.telephone) {
                this.mapping.contact_number = {
                    input: item.telephone.input || item.telephone.phoneNumber || null,
                    number: item.telephone.number || item.telephone.e164 || null,
                    valid: item.telephone.valid || item.telephone.isValid || false,
                    dialCode: item.telephone.dialCode || item.telephone.countryCallingCode || null,
                    countryCode: item.telephone.countryCode || null
                }
            }
        },
        clear_and_close() {
            this.cancel()
            this.reset()
        },
        reset() {
            this.mapping = {
                first_name: null,
                last_name: null,
                role_id: null,
                job_title: null,
                email: null,
                rate: null,
                address: null,
                password: null,
                repeat_password: null,
                contact_number: {
                    input: null,
                    number: null,
                    valid: false,
                    dialCode: null,
                    countryCode: null
                },
            }
        }
    }
}