import { fetch_services } from '@/services/fetch/fetch_services'
import request from '@/services/axios_instance'
//Components
import VueGrid from '@/common/VueGrid/VueGrid.vue'
import VueTable from '@/common/VueTable/VueTable.vue'
import TableHeader from '@/common/TableHeader.vue'
import DeleteDialog from '@/common/DeleteDialog.vue'
import TeamsDialog from './components/TeamsDialog/TeamsDialog.vue'
import Actions from '@/common/VueTable/ActionDropdown.vue'
import GroupsDialog from '@/modules/Settings-Groups/components/GroupsDialog/GroupsDialog.vue'
import { mapGetters } from 'vuex'
import http_build_query from 'js-http-build-query'
import _ from 'lodash'

export default {
    name: 'Teams',
    mixins: [fetch_services],
    components: {
        VueTable,
        TeamsDialog,
        TableHeader,
        DeleteDialog,
        Actions,
        VueGrid,
        GroupsDialog
    },

    data: () => ({
        paths: [{
            text: 'Dashboard',
            disabled: false,
            route: { name: 'default-content' }
        },
        { text: 'Teams', disabled: true, router_name: null }
        ],
        sortList: [
            { title: 'Sort by member' },
            { title: 'Sort by position' },
            { title: 'Sort by location' },
            { title: 'Sort by total hours' },
            { title: 'Sort by total amount' }
        ],
        headers: [
            { text: 'Member', align: 'left', value: 'fullname' },
            { text: 'Position', value: 'job_title' },
            { text: 'Email', value: 'email' },
            { text: 'Tasks', value: 'tasks_count' },
            { text: 'Projects', value: 'projects_count' },
            {
                text: 'Action',
                value: 'action',
                sortable: false,
                align: 'center',
                width: '40px'
            }
        ],
        filter: {
            role: 'all-non-client'
        },
        roles: [],
        role_dialog: false,
        add_group_dialog: false
    }),

    mounted() {
        this.$event.$emit('path-change', this.paths)
    },

    created() {
        // this.load_users()
        this.load_roles()
    },

    computed: {
        ...mapGetters(['user', 'view_type']),
        permissions() {
            return this.$_permissions.get('hq_members')
        },
        can_add() {
            if (this.user.is_admin || this.user.is_manager) {
                return true
            }
            return this.permissions && this.permissions.create
        },
        selectedRoleName() {
            if (this.filter.role === 'all-non-client') return 'All'
            const x = this.roles.find((i) => i.id === this.filter.role)
            return x ? x.name : 'Not found'
        }
    },
    watch: {
        'filter.role': {
            handler: function (val) {
                this.load_users()
            },
            immediate: true
        }
    },
    methods: {
        can_delete(item) {
            if (this.user.is_admin) {
                return true
            }
            return this.permissions && this.permissions.delete
        },
        can_edit(item) {
            if (this.user.is_admin) {
                return true
            }
            if (this.user.id === item.id) {
                return true
            }
            return this.permissions && this.permissions.update
        },
        load_roles() {
            request.get(`api/roles/company`).then(({ data }) => {
                this.roles = _.cloneDeep(data).filter((i) => !i.slug.includes('client'))
            })
        },
        load_users() {
            this.loadItems(
                `api/company/team-members?${http_build_query(this.filter)}`,
                true,
                null,
                null
            )
        },

        toggleAll() {
            if (this.selected.length) this.selected = []
            else this.selected = this.items.slice()
        },

        tasks_text(member) {
            return !member.tasks.length ? 'No tasks assigned' : member.tasks.length
        },

        projects_text(member) {
            return !member.projects.length ?
                'No projects assigned' :
                member.projects.length
        },

        go_to_groups_page() {
            this.$router.push({ path: 'settings?tab=groups' })
        },

        navigate_to_view_profile(user) {
            this.$router.push({
                name: 'profile',
                params: { username: user.username }
            })
        },

        handleSaveGroup(item) {
            request
                .post('api/groups', item)
                .then(({ data }) => {
                    this.roles.push(data)
                    this.appSnackbar('New user group created')
                })
                .finally(() => {
                    this.add_group_dialog = false
                })
        },
        handleSaveUser(payload) {
            this.$store.dispatch('memberProfile/createTeamUser', {
                payload: payload,
                cb: (data) => {
                    this.appSnackbar('New member created.')
                    this.action_add_edit_dialog(false, null)
                    this.insertItem(data, null)
                },
                err_cb: () => {
                    this.$event.$emit('btnloading_off')
                }
            })
        },
        handleUpdateUser(payload) {
            this.$store.dispatch('memberProfile/updateTeamUser', {
                userId: this.activeItem.id,
                payload: payload,
                cb: (data) => {
                    if (this.activeItem && this.activeItem.id === this.user.id) {
                        this.$store.commit('set_login_user', data)
                    }
                    this.replaceItem(data, 'Member info updated.', null)
                    this.load_users()
                    this.action_add_edit_dialog(false, null)
                },
                err_cb: () => {
                    this.$event.$emit('btnloading_off')
                }
            })
        },
        handleDeleteUser() {
            if (this.activeItem.id === this.user.id) {
                this.appSnackbar('Cannot remove self from list.', 'error')
                this.action_confirm_dialog(false, null)
                return
            }
            this.$store.dispatch('memberProfile/deleteTeamUser', {
                userId: this.activeItem.id,
                cb: (data) => {
                    this.removeItem(this.activeItem.id)
                    this.appSnackbar('Member removed.')
                    this.action_confirm_dialog(false, null)
                    this.load_users()
                },
                err_cb: () => {
                    this.$event.$emit('btnloading_off')
                }
            })
        }
    }
}