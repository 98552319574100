import UserCard from '@/common/UserCard.vue'
export default {
    inheritAttrs: false,
    name: 'VueGrid',
    components: {
        UserCard
    },
    props: {
        items: Array,
        icon: String,
        loading: { type: Boolean, default: false },
        emptyText: { type: String, default: 'No data yet' },
        title: { type: String, default: 'Project Members' },
        hasDelete: { type: Boolean, default: true },
        hasEdit: { type: Boolean, default: true },
        hasView: { type: Boolean, default: true },
        noMoreData: { type: Boolean, default: false },
        hasFooter: { type: Boolean, default: true },
        showTaskCount: { type: Boolean, default: true },
        showProjectCount: { type: Boolean, default: true },
        showAffiliation: { type: Boolean, default: false }
    },
    data: () => ({
        btnloading: false
    }),
    computed: {
        logged_user() {
            return this.$store.getters.user
        },
        onlineUsers() {
            return this.$store.getters['onlineUsers/all_users']
        },
        permissions() {
            return this.$_permissions.get('hq_members')
        }
    },
    created() {
        this.$event.$on('btnloading_off', (value) => (this.btnloading = false))
    },
    methods: {
        not_self(item) {
            return this.logged_user.id !== item.id
        },
        handle_action(event) {
            if (this.item) {
                this.$emit(event, this.item)
            }
        },
        can_delete(item) {
            if (!this.hasDelete) return false
            if (this.logged_user.is_admin) {
                return true
            }
            return this.permissions && this.permissions.delete
        },
        can_edit(item) {
            if (!this.hasEdit) return false
            if (this.logged_user.is_admin) {
                return true
            }
            if (this.logged_user.id === item.id) {
                return true
            }
            return this.permissions && this.permissions.update
        },
        can_view() {
            if (this.logged_user.is_admin) {
                return true
            }
            return this.permissions && this.permissions.view
        },
        handleLoadMore() {
            this.btnloading = true
            this.$emit('load-more')
        }
    }
}