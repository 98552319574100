<template>
  <vue-tel-input-vuetify
    :disabled="disabled"
    v-bind="$attrs"
    :label="label"
    outlined
    :rules="[validateNumber]"
    :valid-characters-only="true"
    :preferred-countries="preferredCountries"
    :value="phone.input"
    wrapper-classes="tel-input"
    @input="onInput"
    hide-details="auto"
    dense
    :disabled-fetching-country="disabledFetchingCountry"
    clearable
  ></vue-tel-input-vuetify>
</template>

<script>
export default {
  inheritAttrs: false,
  name: "CustomPhoneInput",
  props: {
    value: { type: [String, Object], default: "" },
    label: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    disabledFetchingCountry: { type: Boolean, default: true },
    preferredCountries: {
      type: Array,
      default: function () {
        return ["US", "PH", "CA", "HK"];
      },
    },
  },
  data: () => ({
    phone: {
      input: null,
      e164: null,
      valid: false,
      dialCode: null,
      countryCode: null,
    },
  }),
  watch: {
    value: {
      handler: function (val) {
        if (typeof val === "string") {
          this.phone.input = val;
        } else if (!val) {
          this.phone = {
            input: null,
            e164: null,
            valid: false,
            dialCode: null,
            countryCode: null,
          };
        } else {
          this.phone = {
            input: val.input ? val.input : val.e164 ? val.e164 : null,
            e164: val.e164 || null,
            valid: val.e164
              ? val.valid
                ? val.valid
                : val.isValid
                ? val.isValid
                : false
              : false,
            dialCode: val.dialCode
              ? val.dialCode
              : val.countryCallingCode
              ? val.countryCallingCode
              : null,
            countryCode: val.countryCode || null,
          };
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    onInput(inputNumber, data) {
      this.phone = {
        input: data.number && data.number.national,
        e164: data.number && data.number.e164,
        valid: data.valid,
        dialCode: data.country && data.country.dialCode,
        countryCode: data.country && data.country.iso2,
      };
      this.$emit("input", this.phone);
    },
    validateNumber(val) {
      if (!this.required && (!val || val.length === 0)) return true;
      if (this.required && (!val || val.length === 0))
        return "This field is required";
      if (this.phone.valid) return true;
      return `Invalid phone number`;
    },
  },
};
</script>

<style lang="scss">
.tel-input {
  .country-code {
    fieldset {
      border: none !important;
    }
    .v-input__slot {
      background: none !important;
    }

    .v-input__slot::before {
      border: none !important;
      min-height: 56px;
    }

    .v-input__slot::after {
      border: none !important;
    }

    .v-select__slot {
      min-width: 60px;
    }

    .v-text-field__details {
      display: none;
    }
  }
  .v-input__slot {
    background: white !important;
  }
}
</style>